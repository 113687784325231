<template>
  <div v-if="loading == true" class="text-center">
      <div style="margin: auto; margin-top: 350px" class="loader"></div>
  </div>
  
  <div v-if="loading == false">
    <!--Sign In-->
  <div v-if="signedIn == false && this.$route.name != 'signup'" class="welcome" style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;">
    <div style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;">
      <h2 style="color: #7500FF"><b>Sign In</b></h2>
      <form @submit.prevent="signIn">
      <div class="form-group">
          <label class="shadowtext" style="color: white">Username or Email</label>
          <input style="border: 2px solid #484848; width: 100%" type="text" placeholder="..." v-model='form2.email' class='form-control' />
      </div>
      <div class="form-group">
          <label class="shadowtext" style="color: white">Password</label>
          <input style="border: 2px solid #484848; width: 100%" type="password" placeholder="..." v-model='form2.password' class='form-control' />
      </div>
      <br>
      <div class="form-group">
        <button style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 100%; height: 50px; border: 2px solid black; text-align: center; font-size: 120%" class='btn btn-1 btn-block'>Sign In</button><br>
      </div>
      </form>
      <br>
      <div class="form-group" style="text-align: center">
        <button style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 100%; height: 40px; border: 2px solid black; text-align: center; font-size: 100%" class='btn btn-2 btn-block' data-bs-toggle="modal" data-bs-target="#myModal" v-on:click="startForgotPassword()">Forgot Password?</button><br>
      </div>
      <!--<br>-->
      <!--<button @click="goToSignUp('')" class='btn btn-2 btn-block' style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 100%; height: 50px; border: 2px solid black; font-size: 120%">Back</button>-->
    </div>
  </div>
  
  <!--Signed In Main Page-->
  
   <div v-if="signedIn == true && userVerified == 'true' || this.$route.name == 'payment'" class="mainbg" :key="orientation">
    <nav style="padding: 3px; text-align: center; border-bottom: 4px solid #7500FF; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; background: #080808" class="navbar navbar-expand-sm navbar-dark" aria-label="Fourth navbar example" v-if="this.$route.name !== 'tv' && this.$route.name !== 'condensed' && this.$route.name !== 'payment'">
      <div class="container-fluid" style="margin: 0px; padding: 0px">
        <a class="nav-link text-light" style="font-size: 160%">OneBand</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExample04">
        <ul class="navbar-nav me-auto mb-2 mb-md-0" style="">
          <li class="nav-item">
            <router-link class="nav-link pr-3 text-light" to="/"><a :style="[this.$route.name == 'gameday' ? {'color':'#FF5000'} : {}]">Gameday</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <router-link class="nav-link text-light" to="/songlist"><a :style="[this.$route.name == 'songlist' ? {'color':'#FF5000'} : {}]">All Music</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <router-link class="nav-link text-light" to="/calendar"><a :style="[this.$route.name == 'calendar' ? {'color':'#FF5000'} : {}]">Calendar</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <router-link class="nav-link text-light" to="/show"><a :style="[this.$route.name == 'show' ? {'color':'#FF5000'} : {}]">Marching Show</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <!--Check permissions-->
          <li v-if="isAdmin == true" class="nav-item">
            <router-link class="nav-link text-light" to="/bdmenu"><a :style="[this.$route.name == 'bdmenu' ? {'color':'#FF5000'} : {}]">Director Menu</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li v-if="isAdmin == true" class="nav-item">
            <router-link class="nav-link text-light" to="/instrumentlist"><a :style="[this.$route.name == 'instrumentlist' ? {'color':'#FF5000'} : {}]">Manage Instruments</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li v-if="isAdmin == true" class="nav-item">
            <router-link class="nav-link text-light" to="/admin"><a :style="[this.$route.name == 'admin' ? {'color':'#FF5000'} : {}]">Manage Users</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <router-link class="nav-link text-light" to="/artscalendar"><a :style="[this.$route.name == 'artscalendar' ? {'color':'#FF5000'} : {}]">Full Arts Calendar</a></router-link>
          </li>&nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <router-link class="nav-link text-light" to="/profile"><a :style="[this.$route.name == 'profile' ? {'color':'#FF5000'} : {}]">Profile</a></router-link>
          </li>
        </ul>
      </div>
      </div>
      
    </nav>
    <div style="padding: 10px">
    <router-view></router-view>
    </div>
    </div>
    
    <!--Verifying Wait-->
    
    <div v-if="userVerified == 'false' && signedIn == true" style="display: flex; align-items: center; justify-content: center">
      <h1 class="text-center">Waiting for Approval... <br><br><button style="width: 80%; margin: auto" v-on:click='signOut' class='btn btn-danger btn-block'>Sign Out</button></h1>
    </div>
    
    <!--Sign Up Exception-->
    
    <div v-if="this.$route.name == 'signup' && signedIn == false" style="padding: 10px">
      <router-view />
    </div>
    
    
    
    
    
    
    <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding: 5px">
            <div v-if="forgotPasswordState == 1">
              <div class="form-group">
                  <label class="shadowtext" style="color: white">Username or Email</label>
                  <input style="border: 2px solid #484848; width: 100%" type="text" placeholder="..." v-model='fpusername' class='form-control' />
              </div>
              <br>
              <div class="form-group text-center">
                <button style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 50%; height: 40px; border: 2px solid black; text-align: center; font-size: 120%" class='btn btn-1 btn-block' @click="forgotPassword()">Send Code</button><br>
              </div>
              <br>
            </div>
            
            <div v-if="forgotPasswordState == 2">
              <div class="form-group">
                  <label>Confirmation Code</label>
                  <input style="border: 2px solid #484848" type="text" placeholder="123456" v-model='code' class='form-control' />
              </div>
              <div class="form-group">
                  <label>New Password (Requires at least 1 capital letter and 1 number)</label>
                  <input style="border: 2px solid #484848" type="password" placeholder="Password" v-model='newPassword' class='form-control' />
              </div>
              <div class="form-group">
                  <label>Confirm New Password</label>
                  <input style="border: 2px solid #484848" type="password" placeholder="Password" v-model='confirmNewPassword' class='form-control' />
              </div>
              <br>
              <div class="form-group text-center">
                <button style="border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; width: 50%; height: 40px; border: 2px solid black; text-align: center; font-size: 120%" class='btn btn-1 btn-block' @click="setNewPassword()">Change Password</button><br>
              </div>
              <br>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
    
      </div>
    </div>
    </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { db } from './firebaseDb';

export default {
  name: "App",
  data() {
            return {
               isAdmin: false,
                useremail: "",
                signedIn: false,
                form: {
                  password: '',
                  email: '',
                },
                form2: {
                  email: '',
                  password: '',
                },
                tenants: [],
                userVerified: 'false',
                hasSignedOut: false,
                username: "",
                maincolor: '#003399',
                forgotPasswordState: 1,
                fpusername: "",
                code: "",
                newPassword: "",
                confirmNewPassword: "",
                loading: true,
                orientation: "",
            }
        },
  created() {
    console.log(this.$Instrument)
      
      Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true
      })
      .catch(() => {
        this.signedIn = false
        this.loading = false;
      })
    const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
          this.loading = true;
                Auth.currentAuthenticatedUser()
      .then(user => {
        this.signedIn = true
        db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().email).toLowerCase() == String(user.email).toLowerCase()) {
                        if(doc.data().approved == 'true' || doc.data().approved == 'admin') {
                          this.userVerified = 'true';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'true'
                          });
                        }
                        if(doc.data().approved == 'false') {
                          this.userVerified = 'false';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'false'
                          });
                        }
                        if(doc.data().approved == 'view') {
                          this.userVerified = 'view';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'view'
                          });
                        }
                        if(doc.data().approved == 'admin') {
                          this.isAdmin = true;
                          this.userVerified = 'true'
                        }
                        this.loading = false;
                    }
                })
                })
        console.log(Auth.currentAuthenticatedUser())
        window.location.reload();
      })
      .catch(() => this.signedIn = false)
                  
            }
        switch (data.payload.event) {
          case 'signOut':
            this.signedIn = false;
        }
    }
  Hub.listen('auth', listener);

  Auth.currentAuthenticatedUser({
            }).then(user => { 
              this.username = user.username;
                  db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                        if(doc.data().approved == 'true' || doc.data().approved == 'admin') {
                          this.userVerified = 'true';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'true'
                          });
                        }
                        if(doc.data().approved == 'false') {
                          this.userVerified = 'false';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'false'
                          });
                        }
                        if(doc.data().approved == 'view') {
                          this.userVerified = 'view';
                          Auth.updateUserAttributes(user, {
                            'custom:approved': 'view'
                          });
                        }
                        if(doc.data().approved == 'admin') {
                          this.isAdmin = true;
                          this.userVerified = 'true'
                        }
                        this.loading = false;
                    }
                })
                });
            })
            console.log("TENANT: "+this.$tenant)
  },
  mounted() {
    const supportsOrientationChange = "onorientationchange" in window;
    const orientationEvent = supportsOrientationChange ? "orientationchange": "resize";

window.addEventListener(
  orientationEvent,
  () => {
    document.body.style.zoom = (window.innerWidth / window.outerWidth)
    this.orientation = window.screen.orientation.type;
    console.log(window.screen.orientation.type)
  },
  false
);
  },
  methods: {
    async signIn() {
      try {
        const { email, password } = this.form2
        await Auth.signIn(email, password)
        this.form = {
                  email: '',
                  password: '',
                }
                this.formState = ''
    } catch (error) {
      if(String(error) !== "Error: Pending sign-in attempt already in progress") {
        if(String(error).includes("Custom auth lambda trigger")) {
          alert("Password cannot be empty.")
        }
        else {
          alert(String(error).split(':')[1]);
        }
      }
    }
    },
      async goToSignUp(state) {
        this.formState = state
    },

    async signOut() {
      await Auth.signOut().then(() => {
        console.log(Auth.currentAuthenticatedUser())
        this.signedIn = false;
        this.hasSignedOut = true;
      })
  },
  startForgotPassword() {
    this.forgotPasswordState = 1
    console.log(this.forgotPasswordState)
  },
  forgotPassword() {
    Auth.forgotPassword(this.fpusername)
    .then((data) => {
      console.log(data); 
      this.forgotPasswordState = 2
      console.log(this.forgotPasswordState)
    })
    .catch((err) => {
          alert(err)
        });
  },
  setNewPassword() {
    if(this.newPassword == this.confirmNewPassword) {
      Auth.forgotPasswordSubmit(this.fpusername, this.code, this.newPassword)
      .then((data) => {
        console.log(data)
        alert("Password Successfully Changed!")
        Auth.signIn(this.fpusername, this.newPassword)
      })
      .catch((err) => {
          alert(err)
        });
    }
    else {
      alert("Passwords Don't Match.")
      this.confirmNewPassword = "";
    }
  },
  },
  
};
</script>

<style>
.btn-1 {
  background-color: #7500FF;
  color: white;
}
.btn-1:hover, .btn-1:active {
  filter: brightness(0.85);
  color: white;
  outline: none !important;
  box-shadow: none !important;
}

.btn-2 {
   background-color: #FF5000;
   color: white;
} 

.btn-2:hover, .btn-2:active {
  filter: brightness(0.85);
  color: white;
  outline: none !important;
  box-shadow: none !important;
}
body {
background: #CCCCCC;
}
.welcome {
  
}
.mainbg {
  
}
.signIn {
  background: linear-gradient(to bottom right, #17B169, #006400);
}
.logo-image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 0px;
    float: right;
    margin-right: 25px;
    margin-bottom: 5px;
}
h4 {
  width: 100%;
  padding: 20px;
}
    .shadowtext {
        text-shadow: 0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000,
	0px 0px 2px #000;
    }
    .shadowtextwhite {
        text-shadow: 0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF,
	0px 0px 1px #FFF;
    }
    .galaxy {
      background: linear-gradient(to bottom right,midnightblue, #1034A6)
    }
    html,body
{
  position: relative;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    font-family: 'Kanit', sans-serif;
}


.dropdown-menu a:hover{
  background: #004aad !important;
}

.loader {
  border: 16px solid #FF5000;
  border-top: 16px solid #7500FF;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
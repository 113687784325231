<template>
    <div v-if="$tenant != 'admintenant'">
        <h1>Sorry, you don't have access to this page.</h1>
    </div>
    <div v-if="$tenant == 'admintenant'">
    <div>
        <div style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;">
            <h3 class="text-center">Invite New User</h3>
            <button @click="copyURL('https://app.onebandsoftware.com/signup/'+NextSong.invitelink)" class="btn btn-1">Copy Invite Link</button>
            <form ref="form" @submit.prevent="sendEmail">
                <div class="form-group">
                    <label>Band Short Name</label>
                    <input type="text" class="form-control" id="bandname" name="user_band" style="padding: 10px; width: 100%" required>
                </div>
                <div class="form-group">
                    <label>School System</label>
                    <input type="text" class="form-control" id="schoolsystem" name="school_system" style="padding: 10px; width: 100%" required>
                </div>
                <br>
                <div class="form-group" style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  left: 25%;
  right: 25%;">
                    <button class="btn btn-1 btn-block">Invite</button>
                </div>
                <div class="form-group">
                    
                </div>
            </form>
        </div>
    </div>
    </div>
</template>
<script>
    import { db } from '../firebaseDb';
    //import emailjs from 'emailjs-com';
    export default {
        data() {
            return {
                message: "",
                email: "",
                user: {
                    username: "",
                    email: "",
                    band: "",
                    invitelink: "",
                    approved: false,
                },
                show: false,
                noemail: true,
                NextSong: {},
                invitelink: "",
                
            }
        },
        created() {
            //this.user.band = this.$tenant;
        },
        mounted() {
          document.getElementById("invitediv").style.visibility = "hidden";  
        },
        methods: {
            create_UUID(){
                var dt = new Date().getTime();
                var uuid = 'xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
                    var r = (dt + Math.random()*16)%16 | 0;
                    dt = Math.floor(dt/16);
                    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
                });
                return uuid;
            },
            copyURL(text) {
                window.navigator.clipboard.writeText(text)
            },
            ChangeInviteLink() {
               
                var justUUID = this.create_UUID();
                
                this.NextSong.invitelink = justUUID;
                this.NextSong.userNum = 1;
                this.NextSong.userCost = 12;
                this.NextSong.school = document.getElementById("schoolsystem").value
                db.collection('NextSong').doc(this.NextSong.tenant).set(this.NextSong).then(() => {
                        console.log("New Link generated!")
                    }).catch((error) => {
                        console.log(error);
                    });
            },
            sendEmail() {
                
                this.user.band = document.getElementById("bandname").value;
                this.user.invitelink = this.create_UUID();
                this.NextSong.tenant = this.user.band;
                this.NextSong.first = true;
                this.NextSong.suggestions = [];
                this.ChangeInviteLink()
                
                },
            onFormSubmit(event) {
                event.preventDefault()
                //send email.then() {
                db.collection('pendingusers').add(this.user).then(() => {
                    alert("Added!");
                    this.bl.name = ''
                    this.bl.notes = ''
                    
                }).catch((error) => {
                    console.log(error);
                });
                //this.$router.push('/bdmenu')
            }
        }
    }
</script>
<template>
  <div v-if="NextSong.name != '' && NextSong.name != undefined">
      <h1 style="text-align: center">Next Song:</h1>
      <h1 style="text-align: center">{{NextSong.name}}<br>
      {{NextSong.notes}}<br>
      {{NextSong.cue}}</h1>
      <div style="text-align: center">
            <button class="btn btn-1" style="font-size: 120%; padding: 5px; width: 40%;" data-bs-toggle="modal" data-bs-target="#myModal" @click="checkNoLink()">View Sheet Music</button>
      </div>
    </div>
    <div v-if="NextSong.name == '' || NextSong.name == undefined">
        <h1 style="text-align: center">No Song Coming Up</h1>    
    </div>
    <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
  <br>
  <div class="row justify-content-center">
        <div class="col-md-5">
            <h2 class="text-center">Suggest A Song</h2>
            <form @submit.prevent="onFormSubmit">
                <div class="form-group">
                    <label>Name</label>
                    <select class=form-control v-model="song.name">
                                <option v-for="(song, id) in Songs" :key="id" :value="song.name">{{song.name}}</option>
                     </select>
                </div>

                <div class="form-group" style="text-align: center">
                    <br>
                    <button class="btn btn-1 btn-block" style="width: 75%; font-size: 120%">Send</button>
                </div>
            </form>
        </div>
    </div>
 <div id="myModal" class="modal fade" role="dialog" style="width: 100%">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;">

    <!-- Modal content-->
    <div class="modal-content" id="musicDisplay" style="width: 100%; height: 100%;">
      <div v-if="darkMode == false" class="modal-header" style="height: 3%; text-align: center; padding-left: 0px; padding-right: 20px; border-bottom: 5px solid black">
          <button style="width: 20%; font-size: 85%" type="button" class="btn btn-dark" @click="SwitchMode" aria-label="Dark Mode">Dark Mode</button>
          <select v-if="noLink == false" style="border-right: 2px solid black; border-left: 0px" class=form-control v-model="instrument" @change="changePart()">
            <option v-for="ins in this.NextSong.links" :key="ins.instrument" :value="ins.instrument">{{ins.instrument}}</option>
        </select>
          <select v-if="noLink == false" style="border-left: 2px solid black; border-right: 0px" class=form-control v-model="part" @change="changePart()">
            <option v-for="part in this.NextSong.links[this.NextSong.links.map((el) => el.instrument).indexOf(this.instrument)].partlinks" :key="part.part" :value="part.part">{{part.part}}</option>
        </select>
          <select v-if="noLink == true" style="border-right: 2px solid black; border-left: 0px" class=form-control v-model="instrument" @change="changePart()">
            <option v-for="ins in this.NextSong.links" :key="ins.instrument" :value="ins.instrument">{{ins.instrument}}</option>
        </select>
        <button @click="ZoomOut()"><i class="fas fa-search-minus"></i></button>
        <button @click="ZoomIn()"><i class="fas fa-search-plus"></i></button>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
      <div v-if="darkMode == true" class="modal-header" style="height: 3%; text-align: center; background-color: black; padding-left: 0px; padding-right: 20px; border-bottom: 5px solid white">
          <button style="width: 20%; font-size: 85%" type="button" class="btn btn-light" @click="SwitchMode" aria-label="Dark Mode">Light Mode</button>
          <select v-if="noLink == false" style="background-color: black; color: white; border-right: 2px solid white; border-left: 0px" class=form-control v-model="instrument" @change="changePart()">
            <option v-for="ins in this.NextSong.links" :key="ins.instrument" :value="ins.instrument">{{ins.instrument}}</option>
        </select>
        <select v-if="noLink == false" style="background-color: black; color: white; border-left: 2px solid white; border-right: 0px" class=form-control v-model="part" @change="changePart()">
            <option v-for="part in this.NextSong.links[this.NextSong.links.map((el) => el.instrument).indexOf(this.instrument)].partlinks" :key="part.part" :value="part.part">{{part.part}}</option>
        </select>
        
          <select v-if="noLink == true" style="background-color: black; color: white; border-right: 2px solid white; border-left: 0px" class=form-control v-model="instrument" @change="changePart()">
            <option v-for="ins in this.NextSong.links" :key="ins.instrument" :value="ins.instrument">{{ins.instrument}}</option>
        </select>
        <button @click="ZoomOut()"><i class="fas fa-search-minus"></i></button>
        <button @click="ZoomIn()"><i class="fas fa-search-plus"></i></button>
          <button type="button" class="btn-close" data-bs-dismiss="modal" style="filter: invert(1)" aria-label="Close"></button>
      </div>
      
      <div v-if="noLink == false" class="modal-body" style="width: 100%; padding:1px; height: 90vh; text-align: center">
        <!--<iframe :src="NextSong.link" style="width:100%; height:100%;" frameborder="0"></iframe>-->
        <img v-if="darkMode == false" :src="NextSong.link" :style="{ width: auto, height: modalHeight }" class="img-responsive"/>
        <img v-if="darkMode == true" :src="NextSong.link" :style="{ width: auto, height: modalHeight, filter: darkModeFilter }" class="img-responsive"/>
      </div>
      
      <div v-if="noLink == true" class="modal-body" style="width: 100%; padding:1px; height: 90vh; text-align: center">
        <!--<iframe :src="NextSong.link" style="width:100%; height:100%;" frameborder="0"></iframe>-->
        <h1>No Sheet Music Uploaded for Your Instrument</h1>
      </div>
    </div>
    </div>
</div>
</template>

<script>
import { db } from '../firebaseDb';
import Auth from '@aws-amplify/auth';

export default {
  name: "App",
  data() {
            return {
               NextSong: {
                 name: "",
                 cue: "",
                 link: "",
                 suggestions: [],
                 tenant: "",
                 links: [{
                     instrument: this.$Instrument,
                     partlinks: [],
                 }],
               },
               song: {
                   name: "",
                   user: "",
               },
               Songs: [],
               darkMode: false,
               instrument: "",
               noLink: true,
               zoom: 100,
               darkModeFilter: 'invert(1)',
            }
        },
  computed: {
      modalHeight:function() {
                return String(this.zoom)+'%'
            }
  },
  created() {
    //   if(this.$Instrument == null) {
    //       setTimeout(function() {
    //           alert("Please select an instrument and a part.")
    //           this.$router.push('/profile')
    //       }, 2500)
    //   }
      this.NextSong.tenant = this.$tenant;
      this.part = this.$defaultpart;
      this.instrument = this.$Instrument
      Auth.currentAuthenticatedUser({
            }).then(user => { 
              this.song.user = user.username;
              console.log(user.username)
            })
            db.collection('Songs').orderBy('name').onSnapshot((snapshotChange) => {
                this.Songs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                        this.Songs.push({
                            key: doc.id,
                            name: doc.data().name,
                        })
                    }
                });
            })
            this.updateNextSong();
  },
  methods: {
      updateNextSong() {
          db.collection('NextSong').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                        console.log(this.NextSong)
                        this.NextSong.name = doc.data().name
                        this.NextSong.first = false;
                        this.NextSong.cue = doc.data().cue
                        this.NextSong.suggestions = doc.data().suggestions
                        this.NextSong.links = doc.data().links;
                        this.NextSong.notes = doc.data().notes;
                        if(this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user) != -1) {
                            this.song.name = this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song
                        }
                        if(doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)] == null) {
                            console.log("HERE1", this.part)
                            while(doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)] == null && this.part > 1) {
                                this.part -= 1;
                                console.log("HERE1", this.part)
                                if(doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)] != null) {
                                    this.NextSong.link = doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)].link
                                    console.log("HERE")
                                }
                            }
                        }
                        else {
                            this.NextSong.link = doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[doc.data().links[doc.data().links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)].link
                        }
                    }
                });
            })
      },
      onFormSubmit(event) {
                event.preventDefault()
                if(this.NextSong.suggestions.length == 0 || this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)] == null) {
                        this.NextSong.suggestions.splice(0, 0, {user: this.song.user, song: this.song.name})
                        db.collection('NextSong').doc(this.$tenant)
                        .update(this.NextSong).then(() => {
                            alert("Suggested!")
                            console.log("Song successfully updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                }
                else {
                if(this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song != "" && this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song  != null) {
                    if(window.confirm("This will replace your earlier suggestion of "+this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song +". Are you sure you want to proceed?")) {
                        this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song = this.song.name;
                        db.collection('NextSong').doc(this.$tenant)
                        .update(this.NextSong).then(() => {
                            alert("Suggested!")
                            console.log("Song successfully updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }
                else {
                    this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.user).indexOf(this.song.user)].song = this.song.name;
                    db.collection('NextSong').doc(this.$tenant)
                    .update(this.NextSong).then(() => {
                        alert("Suggested!")
                        console.log("Song successfully updated!");
                    }).catch((error) => {
                        console.log(error);
                    });
               }
                }
            },
            
            SwitchMode() {
                this.darkMode = !this.darkMode;
                if(this.darkMode == true) {
                    document.getElementById("musicDisplay").style.backgroundColor = 'black';
                }
                else {
                    document.getElementById("musicDisplay").style.backgroundColor = 'white';
                }
            },
            
            changePart() {
                this.NextSong.link = this.NextSong.links[this.NextSong.links.map((el) => el.instrument).indexOf(this.instrument)].partlinks[this.NextSong.links[this.NextSong.links.map((el) => el.instrument).indexOf(this.instrument)].partlinks.map((el) => el.part).indexOf(this.part)].link
                if(this.noLink) {
                        this.noLink = false;
                }
            },
            checkNoLink() {
                this.noLink = true;
                if(this.NextSong.links[this.NextSong.links.map((el) => el.instrument).indexOf(this.instrument)].partlinks == null) {
                    this.noLink = true
                }
                else {
                    this.noLink = false;
                }
            },
            ZoomIn() {
              this.zoom += 5;  
            },
            ZoomOut() {
              this.zoom -= 5;  
            },
  },
  
};
</script>

<style>
.btn-close {
    color: #ff0000; 
    opacity: 1;
}
</style>
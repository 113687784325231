<template>
  <div id="myModal" class="modal fade" role="dialog" style="width: 100%">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;">

    <!-- Modal content-->
    <div class="modal-content" style="width: 100%; height: 100%">
      <div v-if="darkMode == false" class="modal-header" style="height: 3%; text-align: center; padding-left: 0px; padding-right: 20px">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      
      <div class="modal-body" style="width: 100%; padding:1px; height: 90vh; text-align: center">
         <iframe :src="link" style="width:100%; height:100%" frameborder="0"></iframe>
      </div>
    </div>

  </div>
</div>
<br>
  <!--<div style="width: 100%; text-align: center; padding-left: 20px; padding-right: 20px">-->
  <!--      <div class="form-group">-->
  <!--          <h3>Upload New Drill</h3>-->
  <!--          <br>-->
  <!--          <input class="form-control" v-on:change="UpdateInstruments($event)" type="file" id="ins.name" ref="fileInput" accept="image/png, image/jpeg"/>-->
  <!--      </div>-->
  <!--      <br>-->
  <!--    <div class="form-group" style="text-align: center; width: 100%">-->
  <!--          <button v-if="uploading == '' && this.file != null" class="btn btn-1 btn-block" @click="onUpdateForm($event)" style="width: 100%">Upload</button>-->
  <!--          <h1 v-if="uploading != ''">{{uploading}}</h1>-->
  <!--    </div>-->
  <!--    <div style="text-align: center; width: 100%">-->
  <!--          <button class="btn btn-2" style="font-size: 120%; padding: 5px; width: 100%;" data-bs-toggle="modal" data-bs-target="#myModal">View Drill</button>-->
  <!--          <br>-->
  <!--    </div>-->
  <!--</div>-->
  
  <div class="text-center" v-if="NextSong.showmusic != null" style="width: 100%; text-align: center">
    <!--<br>-->
    <h3>Show Audio</h3>
        <br>
    <div v-if="NextSong.showmusic.length == 0 || NextSong.showmusic == null">
      <h4>No Audio Uploaded</h4>
      </div>
    <div v-for="(m, index) in NextSong.showmusic" :key="index">
      <h5>{{m.name}} &nbsp;&nbsp;</h5>
      <h6 v-if="m.tempo != null">Tempo:<input style="width: 20%; margin: auto; text-align: center" class="form-control" type="number" v-model="m.tempo" @change="changeTempo(m)"/></h6>
    <audio controls style="width: 80%" :id="m.name">
    <source :src="m.link" type="audio/mpeg">
    </audio>
    <button v-if="user.approved == 'admin'" class="btn btn-danger" style="width: 80%" @click="deleteShowMusic(m)">Delete</button>
    <br>
    <br>
    </div>
  </div>
  <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
  <div v-if="Videos != null" style="width: 100%; text-align: center">
    <br>
    <h3>Show Videos</h3>
    <br>
    <div v-if="Videos.length == 0 || Videos == null">
      <h4>No Videos Uploaded</h4>
      </div>
    <div v-for="(m, index) in Videos" :key="index">
      <h5>{{m.name}} &nbsp;&nbsp;</h5>
    <iframe class="youtube-embed" width="80%" height="300px" :src="'https://www.youtube.com/embed/'+getId(m.link)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <button v-if="user.approved == 'admin'" class="btn btn-danger" style="width: 80%" @click="deleteVideo(m)">Delete</button>
    <br>
    <br>
    </div>
  </div>
  
  <div v-if="Scores != null && user.approved == 'admin'" style="width: 100%; text-align: center">
    <br>
    <h3>Scores</h3>
    <br>
    <div v-if="Scores.length == 0 || Videos == null">
      <h4>No Scores Uploaded</h4>
      </div>
    <div v-for="(m, index) in Scores" :key="index">
      <h5>{{m.name}} &nbsp;&nbsp;</h5>
    <div style="text-align: center; width: 100%">
            <button class="btn btn-2" style="font-size: 120%; padding: 5px; width: 80%;" data-bs-toggle="modal" data-bs-target="#myModal" @click="ChangeScore(m)">View</button>
            <br>
    </div>
    <button v-if="user.approved == 'admin'" class="btn btn-danger" style="width: 80%" @click="deleteScore(m)">Delete</button>
    <br>
    <br>
    </div>
  </div>
  
  <div v-if="user.approved == 'admin'" style="width: 100%">
    <br>
    <br>
        <div class="form-group">
          <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
            <h3 style="text-align: center">Upload New Show Audio</h3>
            <label>Name/Movement</label>
            <input class="form-control" type="text" v-model="music.name" required/>
            <label>Starting Tempo</label>
            <input class="form-control" type="number" v-model="music.tempo" required/>
            <label>Audio File</label>
            <input id="showaudioinput" class="form-control" v-on:change="UpdateMusic($event)" type="file" ref="fileInput" accept=".mp3"/>
        </div>
        <br>
        <div class="form-group" style="text-align: center; width: 100%">
            <button v-if="uploadingmusic == '' && this.musicfile != null && music.name != null && music.name != ''" class="btn btn-1 btn-block" @click="onUpdateMusicForm($event)" style="width: 100%">Upload</button>
            <h1 v-if="uploadingmusic != ''">{{uploadingmusic}}</h1>
      </div>
  </div>
  
  <div v-if="user.approved == 'admin'" style="width: 100%">
    <br>
    <br>
        <div class="form-group">
          <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
            <h3 style="text-align: center">Upload New Show Video</h3>
            <label>Name</label>
            <input class="form-control" type="text" v-model="video.name" required/>
            <label>Link</label>
            <input class="form-control" type="text" v-model="video.link" required/>
        </div>
        <br>
        <div class="form-group" style="text-align: center; width: 100%">
            <button v-if="this.video.name != null && video.name != '' && video.link != null && video.link != ''" class="btn btn-1 btn-block" @click="AddVideo()" style="width: 100%">Upload</button>
      </div>
  </div>
  
  <div v-if="user.approved == 'admin'" style="width: 100%">
    <br>
    <br>
        <div class="form-group">
          <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
            <h3 style="text-align: center">Upload New Score</h3>
            <label>Name/Movement</label>
            <input class="form-control" type="text" v-model="score.name" required/>
            <label>PDF File</label>
            <input id="showscoreinput" class="form-control" v-on:change="UpdateScore($event)" type="file" ref="fileInput" accept="application/pdf"/>
        </div>
        <br>
        <div class="form-group" style="text-align: center; width: 100%">
            <button v-if="uploadingscore == '' && this.scorefile != null && score.name != null && score.name != ''" class="btn btn-1 btn-block" @click="onUpdateScoreForm($event)" style="width: 100%">Upload</button>
            <h1 v-if="uploadingscore != ''">{{uploadingscore}}</h1>
      </div>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import { db } from '../firebaseDb';
import {UploadToS3} from '../s3Upload';
import {getS3Link} from '../s3Upload';
import {DeleteS3} from '../s3Upload';

export default {
  name: "App",
  data() {
            return {
                user: {},
                uploading: "",
                uploadingmusic: "",
                file: null,
                musicfile: null,
                userID: "",
                darkMode: false,
                NextSong: {
                },
                music: {},
                s3link: "",
                video: {},
                Videos: [],
                tempo: 100,
                Scores: [],
                scorefile: null,
                uploadingscore: "",
                score: {},
                loading: false,
                link: "",
            }
        },
  created() {
  console.log(this.$tenant)
  Auth.currentAuthenticatedUser({
            }).then(user => { 
              this.username = user.username;
              let dbRef = db.collection('NextSong').doc(this.$tenant); 
                dbRef.get().then((doc) => { 
                    this.NextSong = doc.data()
                    console.log(this.NextSong.showmusic)
                    if(this.NextSong.showmusic == null) {
                      this.NextSong.showmusic = [];
                    }
                }).catch((error) => { 
                    console.log(error) 
                })
              db.collection('pendingusers').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                  if(doc.data().username == this.username) {
                    this.user = doc.data();
                    this.userID = doc.id;
                  }
                })
                })
            });
            
    db.collection('Videos').orderBy('name').onSnapshot((snapshotChange) => {
                this.Videos = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                        this.Videos.push({
                            name: doc.data().name,
                            link: doc.data().link,
                            key: doc.id,
                        })
                    }
                });
            })
    db.collection('Scores').orderBy('name').onSnapshot((snapshotChange) => {
                this.Scores = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                        this.Scores.push({
                            name: doc.data().name,
                            link: doc.data().link,
                            key: doc.id,
                        })
                    }
                });
            })
  },
  methods: {
  UpdateInstruments(event) {
                const files = event.target.files
              const fileReader = new FileReader()
              fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              this.file = files[0];
            },
            
    UpdateMusic(event) {
        const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.musicfile = files[0];
    },
    
    UpdateScore(event) {
        const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.scorefile = files[0];
    },
            
  
  onUpdateForm(event) {
                event.preventDefault()
                this.uploading = "Uploading...";
                if(this.file != null) {
                    console.log(this.file.path)
                        UploadToS3(this.file, this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+"/drill/"+this.user.username.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()).then(() => {
                        this.s3link = getS3Link();
                        console.log(this.s3link)
                        this.user.drilllink = this.s3link;
                        db.collection('pendingusers').doc(this.userID)
                        .update(this.user).then(() => {
                            alert("Drill Uploaded")
                            this.uploading = "";
                        }).catch((error) => {
                            console.log(error);
                        });
                    })
                }
            },
            
  onUpdateMusicForm(event) {
                event.preventDefault()
                this.uploadingmusic = "Uploading...";
                if(this.musicfile != null) {
                    console.log(this.musicfile.path)
                        UploadToS3(this.musicfile, this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+"/showmusic/"+this.music.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()).then(() => {
                        this.s3link = getS3Link();
                        console.log(this.s3link)
                        this.NextSong.showmusic.splice(0, 0, {"name": this.music.name, "link": this.s3link, "tempo": this.music.tempo, "originaltempo": this.music.tempo})
                        db.collection('NextSong').doc(this.$tenant)
                        .update(this.NextSong).then(() => {
                            this.uploadingmusic = "";
                            document.getElementById('showaudioinput').value = null;
                            this.musicfile = null;
                            alert("Music Uploaded!")
                        }).catch((error) => {
                            console.log(error);
                        });
                    })
                }
            },
  onUpdateScoreForm(event) {
                event.preventDefault()
                this.uploadingscore = "Uploading...";
                if(this.scorefile != null) {
                    console.log(this.scorefile.path)
                        UploadToS3(this.scorefile, this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+"/showscores/"+this.score.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()).then(() => {
                        this.s3link = getS3Link();
                        console.log(this.s3link)
                        this.score.tenant = this.$tenant;
                        this.score.link = this.s3link;
                        db.collection('Scores').add(this.score).then(() => {
                            this.uploadingscore = "";
                            document.getElementById('showscoreinput').value = null;
                            this.musicfile = null;
                            alert("Score Uploaded!")
                        }).catch((error) => {
                            console.log(error);
                        });
                    })
                }
            },
  async deleteShowMusic(music) {
    if(window.confirm("Are you sure you want to delete "+music.name+"?")) {
      var index = 0
        await this.NextSong.showmusic.forEach((m) => {
           if(m.name == music.name) {
              this.NextSong.showmusic.splice(index, 1)
           }
           index += 1
        })
          db.collection('NextSong').doc(this.$tenant)
                        .update(this.NextSong).then(() => {
                            console.log("Deleted")
                        }).catch((error) => {
                            console.log(error);
                        });
          DeleteS3(this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+"/showmusic/"+music.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()).then(() => {
            alert("Deleted!");
          })
    }
  },
  SwitchMode() {
      this.darkMode = !this.darkMode; 
  },
  
  AddVideo() {
    this.video.tenant = this.$tenant
    db.collection('Videos').add(this.video).then(() => {
      alert("Uploaded!")
      this.video.name = "";
      this.video.link = "";
    })
  },
  async deleteVideo(video) {
    if(window.confirm("Are you sure you want to delete "+video.name+"?")) {
      db.collection('Videos').doc(video.key).delete()
    }
  },
  async deleteScore(score) {
    if(window.confirm("Are you sure you want to delete "+score.name+"?")) {
      db.collection('Scores').doc(score.key).delete().then(() => {
        DeleteS3(this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+"/showscores/"+score.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()).then(() => {
            alert("Deleted!");
      })
      })
    }
  },
  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  },
  changeTempo(m) {
    document.getElementById(m.name).playbackRate = m.tempo/m.originaltempo;
  },
  ChangeScore(score) {
    this.link = "https://docs.google.com/gview?url="+String(this.Scores[this.Scores.map((el) => el.name).indexOf(score.name)].link)+'&embedded=true'
    this.loading = true;
    //document.getElementById("loadingDiv").style.display = "block"
    console.log(this.link)
  },
  }
};
</script>
<style>
:root {
  --video-ratio: calc(16 / 9);
  --video-container-max-width: 640px;
  --video-container-x-padding-sum: 2rem; /* eg, padding: 0 1rem */
}

.youtube-embed {
  --video-container-width: calc(80vw - var(--video-container-x-padding-sum));
  width: 80%;
  height: calc(var(--video-container-width) / var(--video-ratio));
}

@media only screen and (min-width: 672px) {
  .youtube-embed {
    --video-container-width: var(--video-container-max-width);
  }
}
</style>
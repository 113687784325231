<template>
     <div class="row justify-content-center" style="float: left; width: 50%; padding: 5px">
        <div class="col-md-5">
            <h3 class="text-center">Set Next Song</h3>
            <form @submit.prevent="onFormSubmitNextSong">
                <div class="form-group">
                    <label>Name</label>
                    <select class=form-control v-model="NextSong.name" required>
                                <option v-for="(song, id) in Songs" :key="id" :value="song.name">{{song.name}}</option>
                     </select>
                </div>
                <div class="form-group">
                    <label>Cue</label>
                    <textarea class="form-control" v-model="NextSong.cue" required></textarea>
                </div>
                <div class="form-group">
                    <label>Notes (Starting at, ending at etc.)</label>
                    <textarea class="form-control" v-model="NextSong.notes"></textarea>
                </div>
                <br>
                <div class="form-group" style="text-align:center">
                    <button style="width: 50%; font-size: 110%" class="btn btn-1 btn-block">Save</button>
                </div>
            </form>
            <br>
            <br>
        </div>
    </div>
    
    <div class="row" style="float: right; width: 50%;">
        <div class="col-md-12">
          <h1 style="text-align: center">Song Suggestions</h1>
          <div style="overflow-y:auto; height: 400px">
            <table class="table table-striped" style="">
                <thead>
                    <tr>
                        <th></th>
                        <!--<th v-if="formattedSuggestions.length != 0"></th>-->
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="formattedSuggestions.length == 0"><h3 style="text-align: center">No Suggestions</h3></tr>
                    <tr v-for="song in formattedSuggestions" :key="song.index">
                        <td class="text-center">{{ song }}<br><br>
                        <button @click.prevent="deleteSongSuggestion(song)" class="btn btn-danger" style="width: 100%">Delete</button></td>
                        <!--<td>-->
                        <!--    <button @click.prevent="deleteSongSuggestion(song)" class="btn btn-danger">Delete</button>-->
                        <!--</td>-->
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
    &nbsp;&nbsp;
    <hr style="width:100%; height:6px; background-color: black; border-radius:5px; opacity:1"/>
    <div class="row">
        <div class="col-md-12">
          <h1 style="text-align: center">Song List <br><button @click="$router.push('/songadd')" class="btn btn-1" style="font-size: 80%; padding: 5px;">Add Song</button></h1>
          <div style="margin-left: 15%; margin-right: 15%">
    </div>
    <div style="overflow-y:auto; height: 500px">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th v-if="Songs.length != 0"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="Songs.length == 0" style="width: 100%"><td><h3 style="text-align: center">You haven't added any songs</h3></td>
                    </tr>
                    <tr v-for="song in Songs" :key="song.key">
                        <td align="center" style="padding: 0px"><h4>{{ song.name }}</h4></td>
                            <td>
                                <router-link :to="{name: 'SongEdit', params: { id: song.key }}" class="btn btn-1" style="width: 50%;">Edit
                            </router-link>
                            <router-link :to="{name: 'printmusic', params: { id: song.key }}" class="btn btn-2" style="width: 50%;"><a style="">Print</a>
                            </router-link>
                            <button @click.prevent="deleteSong(song)" class="btn btn-danger" style="width: 100%; text-align: center;">Delete</button>
                            </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
    <br>
  <br>
    
    <div v-if="$tenant == 'admintenant'" style="padding: 0x; text-align: center">
            <br>
        <hr style="width:100%; height:6px; background-color: #007ADD; border-radius:5px; opacity:1"/>
      <br>
        <h1>SUPER ADMIN!</h1>
        <button @click="$router.push('/superadmin')" class="btn btn-1" style="font-size: 110%; padding: 5px; width: 50%">Add New Band</button>
  </div>
</template>

<script>
    import { db } from '../firebaseDb';
    import { DeleteS3 } from '../s3Upload'
    export default {
        data() {
            return {
                Songs: [],
                song: {
                },
                NextSong: {
                 name: "",
                 cue: "",
                 links: "",
                 suggestions: {},
                 tenant: "",
                 notes: "",
               },
               formattedSuggestions: [],
               Changing: false,
            }
        },
        created() {
            this.Changing = false
            this.updatesongs();
        },
        methods: {
          updatesongs() {
                db.collection('Songs').orderBy('name').onSnapshot((snapshotChange) => {
                this.Songs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                        this.Songs.push({
                            key: doc.id,
                            name: doc.data().name,
                            links: doc.data().links,
                            parentSong: doc.data().parentSong,
                        })
                    }
                });
            })
            
            db.collection('NextSong').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.$tenant) {
                      if(this.Changing == false) {
                           this.NextSong.name = doc.data().name
                           this.NextSong.cue = doc.data().cue
                           this.NextSong.suggestions = doc.data().suggestions
                           this.NextSong.tenant = doc.data().tenant
                           this.NextSong.links = doc.data().links
                           this.NextSong.notes = doc.data().notes
                           this.formattedSuggestions = [];
                           console.log(this.NextSong.suggestions)
                           if(this.NextSong.suggestions != [] && this.NextSong.suggestions != {} && this.NextSong.suggestions != undefined) {
                               this.NextSong.suggestions.forEach((sugg) => {
                                   if(sugg.song != "") {
                                        this.formattedSuggestions.splice(0, 0, sugg.song)
                                   }
                                })
                           }
                      }
                    }
                    });
            })
            },
            
            async deleteSong(song){
              if (window.confirm("Do you really want to delete?")) {
                  if(this.Songs[this.Songs.map((el) => el.key).indexOf(song.parentSong)] == undefined) {
                      for await(const link of song.links) {
                        for await(const part of link.partlinks) {
                            DeleteS3(this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase()+part.link.replace('https://band-project-bucket.s3.us-west-2.amazonaws.com/'+this.$tenant.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '').toLowerCase(), ''))
                        }
                        }
                  }
              db.collection("Songs").doc(song.key).delete()
              }
            },
            deleteSongSuggestion(song) {
               this.NextSong.suggestions[this.NextSong.suggestions.map((el) => el.song).indexOf(song)].song = "";
                db.collection('NextSong').doc(this.$tenant)
                .update(this.NextSong).then(() => {
                    console.log("Song successfully updated!");
                    this.formattedSuggestions = [];
                    this.NextSong.suggestions.forEach((sugg) => {
                           if(sugg.song != "") {
                                this.formattedSuggestions.splice(0, 0, sugg.song)
                           }
                        })
                }).catch((error) => {
                    console.log(error);
                });
            },
            onFormSubmitNextSong() {
                console.log(this.Songs[this.Songs.map((el) => el.name).indexOf(this.NextSong.name)])
                this.NextSong.tenant = this.$tenant;
                this.NextSong.links = this.Songs[this.Songs.map((el) => el.name).indexOf(this.NextSong.name)].links
                if(this.NextSong.notes == null) {
                    this.NextSong.notes = "";
                }
                console.log("LINKS: ", this.NextSong.links)
                if(db.collection('NextSong').doc(this.$tenant) == undefined) {
                    db.collection('NextSong').add(this.NextSong).then(() => {
                            alert("Song successfully updated!");
                            }).catch((error) => {
                                console.log(error);
                            });
                }
                else {
                    db.collection('NextSong').doc(this.$tenant)
                    .update(this.NextSong).then(() => {
                        alert("Next Song Changed!")
                        console.log("Song successfully updated!");
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        }
    }
</script>
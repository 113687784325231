<template>
  <div v-if="this.$isAdmin == true" style="text-align: center">
    <button class="btn btn-1" style="font-size: 120%; padding: 5px; width: 75%;" data-bs-toggle="modal" data-bs-target="#myModal" @click="startAdd()">Add Event</button>
    <br>&nbsp;
  </div>
  <div class="text-center">
    <button v-if="archive == false" class="btn btn-1" style="font-size: 120%; padding: 5px; width: 75%;" @click="GoToArchive(true)">Past Events</button>
    <button v-if="archive == true" class="btn btn-1" style="font-size: 120%; padding: 5px; width: 75%;" @click="GoToArchive(false)">Current Events</button>
  </div>
  <br>
  <h3 style="width: 75%; margin-left:auto;
margin-right:auto;"><input type='text' class="form-control" placeholder="Search for an event by name or date" v-model="search"/></h3>
<div style="overflow-y:auto; height: 750px">
  <div class="text-center" v-if="filteredSearch.length == 0">
    <br>
    <br>
    <h1>No Events</h1>
  </div>
  <div v-if="filteredSearch.length > 0">
    <div class="card-columns" v-for="event in filteredSearch" :key="event.id">


  <div class="card text-dark card bg-light" style="width: 75%; margin-left:auto;
margin-right:auto; margin-bottom:0px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
    <div class="card-header"><h2>{{event.title}}</h2></div>
    <div v-if="event.loc != null && event.loc != ''" class="card-body"><b>Location: </b><a :href="'https://maps.google.com/maps?q=' + event.address" target="_blank">{{event.loc}}</a></div>
    <div class="card-body"><b>Call Time: </b>{{ formatDate(event.calltime) }}</div>
    <div class="card-body">
                        <b>Starts:</b> {{ new Date(event.start).toLocaleString([], {weekday: 'long'}) }}
                        {{ new Date(event.start).toLocaleString([], {year: 'numeric', month: 'long', day: 'numeric'}) }} at
                        {{ new Date(event.start).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</div>
    <div class="card-body"><b>Ends:</b> {{ new Date(event.end).toLocaleString([], {weekday: 'long'}) }}
    {{ new Date(event.end).toLocaleString([], {year: 'numeric', month: 'long', day: 'numeric'}) }} at
    {{ new Date(event.end).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</div>
    <div v-if="event.details != null && event.details != ''" class="card-body"><b>Details:</b> {{event.details}}</div>
    <div class="card-body"><b>Duration:</b> {{timeConvert(Math.floor(((new Date(event.end).getTime() - new Date(event.start).getTime()) / 1000) / 60))}}</div>
    <div v-if="this.$isAdmin == true" class="card-footer">
      <button class="btn btn-1" style="font-size: 120%; width: 100%;" data-bs-toggle="modal" data-bs-target="#myModalEdit" @click="editEventStart(event)">Edit Event</button>
      <button class="btn btn-danger" style="font-size: 120%; width: 100%;" @click="DeleteEvent(event)">Delete Event</button>
    </div>
</div>
<br>
</div>
</div>
</div>
    
    
    <div id="myModal" class="modal fade" role="dialog" style="width: 100%">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;">

    <!-- Modal content-->
    <div class="modal-content" style="width: 100%; height: 100%">
      <div class="modal-header" style="height: 8%; text-align: center; padding-right: 20px;">
          <h1 class="text-center" style="text-align: center; float: center">Add Event</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="float: right"></button>
      </div>
      <div class="modal-body" style="width: 100%; height: 90vh; padding: 20px">
        <form @submit.prevent="AddEvent()">
          <label>Event Title:</label>
          <input type="text" class="form-control" v-model="event.title" placeholder="Event Title" required/>
          <br>
          <label>Location:</label>
          <br>
          <input style="width: 50%; float: left" type="text" class="form-control" v-model="event.loc" placeholder="Name"/><input style="width: 50%; float: right" type="button" class="btn btn-1 btn-block" data-bs-toggle="modal" data-bs-target="#locations" value="Use a Saved Location"/>
          <input type="text" class="form-control" v-model="event.address" placeholder="Address"/>
          <div class="text-center" style="padding-top: 10px">
          <input type="button" v-if="Locations[Locations.map((el) => el.name).indexOf(event.loc)] == null && event.loc != ''" style="width: 100%" class="btn btn-2 btn-block" @click="SaveLocation(event)" value="Save This Location"/>
          <input v-if="Locations[Locations.map((el) => el.name).indexOf(event.loc)] != null && event.loc != '' && Locations[Locations.map((el) => el.name).indexOf(event.loc)].address != event.address" type="button" style="width: 100%" class="btn btn-2 btn-block" @click="SaveLocation(event)" value="Update This Location With New Address"/>
          </div>
          <br>
          <label>Start Time:</label>
          <input type="datetime-local" class="form-control" v-model="event.start" required/>
          <br>
          <label>End Time:</label>
          <input type="datetime-local" class="form-control" v-model="event.end" required/>
          <br>
          <label>Call Time:</label>
          <input type="time" class="form-control" v-model="event.calltime" required/>
          <br>
          <label>Event Details:</label>
          <textarea rows="5" type="text" class="form-control" v-model="event.details" placeholder="What is it? What should people bring? etc."/>
          <br>
          <div style="text-align: center">
            <button class="btn btn-1" style="width: 75%" data-bs-dismiss="modal">Add Event</button>
          </div>
        </form>
      </div>
    </div>

  </div>
  </div>
  
  
  <div id="myModalEdit" class="modal fade" role="dialog" style="width: 100%">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;">

    <!-- Modal content-->
    <div class="modal-content" style="width: 100%; height: 100%">
      <div class="modal-header" style="height: 8%; text-align: center; padding-right: 20px;">
          <h1 class="text-center" style="text-align: center; float: center">Edit Event</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="float: right"></button>
      </div>
      <div class="modal-body" style="width: 100%; height: 90vh; padding: 20px">
        <form @submit.prevent="EditEvent()">
          <label>Event Title:</label>
          <input type="text" class="form-control" v-model="editEvent.title" placeholder="Event Title..." required/>
          <br>
          <label>Location:</label>
          <br>
          <input style="width: 50%; float: left" type="text" class="form-control" v-model="editEvent.loc" placeholder="Name"/><input style="width: 50%; float: right" type="button" class="btn btn-1 btn-block" data-bs-toggle="modal" data-bs-target="#locations" value="Use a Saved Location"/>
          <input type="text" class="form-control" v-model="editEvent.address" placeholder="Address"/>
          <div class="text-center" style="padding-top: 10px">
          <input v-if="Locations[Locations.map((el) => el.name).indexOf(editEvent.loc)] == null && editEvent.loc != ''" type="button" style="width: 100%" class="btn btn-2 btn-block" @click="SaveLocation(editEvent)" value="Save This Location"/>
          <input v-if="Locations[Locations.map((el) => el.name).indexOf(editEvent.loc)] != null && editEvent.loc != '' && Locations[Locations.map((el) => el.name).indexOf(editEvent.loc)].address != editEvent.address" type="button" style="width: 100%" class="btn btn-2 btn-block" @click="SaveLocation(editEvent)" value="Update This Location With New Address"/>
          </div>
          <br>
          <label>Start Time:</label>
          <input type="datetime-local" class="form-control" v-model="editEvent.start" required/>
          <br>
          <label>End Time:</label>
          <input type="datetime-local" class="form-control" v-model="editEvent.end" required/>
          <br>
          <label>Call Time:</label>
          <input type="time" class="form-control" v-model="editEvent.calltime" required/>
          <br>
          <label>Event Details:</label>
          <textarea rows="5" type="text" class="form-control" v-model="editEvent.details" placeholder="What is it? What should people bring? etc."/>
          <br>
          <div style="text-align: center">
            <button class="btn btn-1" style="width: 75%" data-bs-dismiss="modal">Save</button>
          </div>
        </form>
      </div>
    </div>

  </div>
  </div>
  
  
  <div id="locations" class="modal fade" role="dialog">
                              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            
                                <!-- Modal content-->
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h2>Choose a Location</h2>
                                    <button v-if="editing == false" type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#myModal" aria-label="Close"></button>
                                    <button v-if="editing == true" type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#myModalEdit" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body" style="padding: 1px">
                                    <div class="form-group">
                                        <input type='text' class="form-control" placeholder="Search" v-model="search"/>
                                    </div>
                                    <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="loc in Locations" :key="loc.key">
                                                    <td>{{ loc.name }}</td>
                                                    <td>
                                                        <button v-if="editing == false" type="button" class="btn btn-1" style="width: 100%; text-align: center;" data-bs-dismiss="modal" @click="UseLocation(loc)" data-bs-toggle="modal" data-bs-target="#myModal">Use</button>
                                                        <button v-if="editing == true" type="button" class="btn btn-1" style="width: 100%; text-align: center;" data-bs-dismiss="modal" @click="UseLocation(loc)" data-bs-toggle="modal" data-bs-target="#myModalEdit">Use</button>
                                                        <button @click.prevent="DeleteLocation(loc.key)" class="btn btn-danger" style="width: 100%; text-align: center;">Delete</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            
                              </div>
                            </div>
</template>

<script>
var theid = "";
//TODO: Modal pop up to add event
import { db } from '../firebaseDb';


export default {
  name: "App",
  data() {
          return {
            Events: [],
            event: {
              title: "",
              start: "",
              end: "",
              details: "",
              school: "",
              address: "",
              loc: "",
              calltime: "",
            },
            editEvent: {
              title: "",
              start: "",
              end: "",
              details: "",
              school: "",
              address: "",
              loc: "",
              calltime: "",
            },
            search: "",
            Locations: [],
            editing: false,
            archive: false,
            ArchivedEvents: [],
            }
        },
        computed: {
            filteredSearch:function() {
              if(this.archive == false) {
                return this.Events.filter((event) => {
                    var theEvent = event.title + new Date(event.start).toLocaleString([], {'weekday': 'long', 'month': 'long', 'day': 'numeric', 'hour': '2-digit', 'minute': '2-digit'});
                    return theEvent.toLowerCase().includes(this.search.toLowerCase());
                })
              }
              else {
                return this.ArchivedEvents.filter((event) => {
                    var theEvent = event.title + new Date(event.start).toLocaleString([], {'weekday': 'long', 'month': 'long', 'day': 'numeric', 'hour': '2-digit', 'minute': '2-digit'});
                    return theEvent.toLowerCase().includes(this.search.toLowerCase());
                })
              }
            }
        },
  created() {
      this.event.school = this.$school;
      console.log(this.event)
      db.collection('CalendarEvents').orderBy('start').onSnapshot((snapshotChange) => {
                this.Events = [];
                this.ArchivedEvents = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().school == this.$school) {
                      if(new Date(doc.data().end).getTime() >= new Date().getTime()) {
                        this.Events.push({
                            id: doc.id,
                            title: doc.data().title,
                            start: doc.data().start,
                            calltime: doc.data().calltime,
                            details: doc.data().details,
                            end: doc.data().end,
                            school: doc.data().school,
                            address: doc.data().address,
                            loc: doc.data().loc,
                        })
                      }
                      else {
                        this.ArchivedEvents.push({
                            id: doc.id,
                            title: doc.data().title,
                            start: doc.data().start,
                            calltime: doc.data().calltime,
                            details: doc.data().details,
                            end: doc.data().end,
                            school: doc.data().school,
                            address: doc.data().address,
                            loc: doc.data().loc,
                        })
                      }
                    }
                });
            })
      db.collection('SavedLocations').orderBy('name').onSnapshot((snapshotChange) => {
        this.Locations = [];
        snapshotChange.forEach((doc) => {
          if(doc.data().tenant == this.$tenant) {
            this.Locations.push({
              key: doc.id,
              name: doc.data().name,
              address: doc.data().address,
            })
          }
        })
      })
  },
  methods: {
    SetEvent(info) {
      this.event.title = info.event.title;
      this.event.start = info.event.start;
      this.event.end = info.event.end;
      this.event.school = info.event.school;
    },
    closeModal() {
      document.getElementById('myModalEdit').style.display = 'none'
    },
    deleteEvent() {
      if (window.confirm("Do you really want to delete?")) {
              db.collection("CalendarEvents").doc(theid).delete().then(() => {
                document.getElementById('myModalEdit').style.display = 'none'
                alert("Event Deleted!")
              })
              }
    },
    AddEvent() {
      //Add event to database
      this.event.school = this.$school
      if(this.event.address == null) {
        this.event.address = ''
      }
      db.collection('CalendarEvents').add(this.event).then(() => {
        alert("Event Added!")
        this.event = {}
      })
      //this.calendarOptions.events.splice(0, 0, this.event);
    },
    editEventStart(event) {
      this.editing = true;
      this.editEvent = event;
    },
    EditEvent() {
      if(this.editEvent.details == null) {
        this.editEvent.details = ''
      }
      if(this.editEvent.address == null) {
        this.editEvent.address = ''
      }
      db.collection('CalendarEvents').doc(this.editEvent.id).update(this.editEvent).then(() => {
            alert("Updated");
            this.editEvent = {}
            }).catch((error) => {
                console.log(error);
            });
    },
    DeleteEvent(event) {
      if(window.confirm("Are you sure you want to delete "+event.title+"? It will be gone forever.")) {
        db.collection('CalendarEvents').doc(event.id).delete()
      }
    },
    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + " hour(s) and " + rminutes + " minute(s).";
      },
      UseLocation(loc) {
        this.event.loc = loc.name;
        this.event.address = loc.address;
        this.editEvent.loc = loc.name;
        this.editEvent.address = loc.address;
      },
      DeleteLocation(id) {
        if (window.confirm("Do you really want to delete?")) {
              db.collection("SavedLocations").doc(id).delete()
        }
      },
      SaveLocation(event) {
        if(event.address == null) {
          event.address = '';
        }
        if(this.Locations[this.Locations.map((el) => el.name).indexOf(event.loc)]) {
          db.collection("SavedLocations").doc(this.Locations[this.Locations.map((el) => el.name).indexOf(event.loc)].key).update({"name": event.loc, "address": event.address, "tenant": this.$tenant}).then(() => {
            alert("Location Saved!")
          }).catch((error) => {
            console.log(error)
          })
        }
        else {
          if(event.loc == null || event.loc == '') {
            alert("Please input a location to save it.")
          }
          else {
            db.collection('SavedLocations').add({"name": event.loc, "address": event.address, "tenant": this.$tenant}).then(() => {
              alert("Location Saved!")
            }).catch((error) => {
              console.log(error)
            })
          }
        }
      },
      startAdd() {
        this.editing = false;
      },
      formatDate(time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
      },
      GoToArchive(bool) {
        this.archive = bool;
      },
  }
};
</script>

<style>

</style>
import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/signup/:id',
    name: 'signup',
    component: () => import('../components/SignUp')
  },
  {
    path: '/adminadd',
    name: 'adminadd',
    component: () => import('../components/AdminAdd')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../components/Admin')
  },
    {
    path: '/bdmenu',
    name: 'bdmenu',
    component: () => import('../components/BDMenu')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../components/Calendar')
  },
  {
    path: '/artscalendar',
    name: 'artscalendar',
    component: () => import('../components/ArtsCalendar')
  },
  {
    path: '/songadd',
    name: 'songadd',
    component: () => import('../components/SongAdd')
  },
   {
    path: '/songedit/:id',
    name: 'SongEdit',
    component: () => import('../components/SongEdit')
  },
    {
    path: '/songlist',
    name: 'songlist',
    component: () => import('../components/SongList')
  },
   {
    path: '/instrumentadd',
    name: 'instrumentadd',
    component: () => import('../components/InstrumentAdd')
  },
   {
    path: '/instrumentedit/:id',
    name: 'instrumentEdit',
    component: () => import('../components/InstrumentEdit')
  },
    {
    path: '/instrumentlist',
    name: 'instrumentlist',
    component: () => import('../components/InstrumentList')
  },
    {
    path: '/',
    name: 'gameday',
    component: () => import('../components/Gameday')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/Profile')
  },
  {
    path: '/superadmin',
    name: 'superadmin',
    component: () => import('../components/SuperAdminAdd')
  },
   {
    path: '/printmusic/:id',
    name: 'printmusic',
    component: () => import('../components/PrintMenu')
  },
  {
    path: '/payment/:id?',
    name: 'payment',
    component: () => import('../components/Payment')
  },
  {
    path: '/show',
    name: 'show',
    component: () => import('../components/Show')
  },
  {
    path: '/useredit/:id',
    name: 'useredit',
    component: () => import('../components/UserEdit')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
import { createApp } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import Auth from '@aws-amplify/auth';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import './registerServiceWorker'
import { db } from './firebaseDb';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
loadFonts()

const app = createApp(App);
app.use(router);
// db.collection('pendingusers').onSnapshot((snapshotChange) => {
//   snapshotChange.forEach((doc) => {
//     console.log("AAAA")
//     if(doc.data().email == "tcantrell@student.adacougars.net") {
//       db.collection('pendingusers').doc(doc.id).delete().then(() => {
//         console.log(doc.data().email + "Deleted!");
//       })
//     }
//   })
// })
Auth.currentAuthenticatedUser({
            }).then(user => { 
                db.collection('pendingusers').onSnapshot((snapshotChange) => {
                  SetUser(snapshotChange, user);
                })
              
            }).then(() => {
              //app.config.globalProperties.$tenant = thetenant;
              console.log("GLOBAL; "+app.config.globalProperties.$tenant)
              })
app.mount('#app');  

async function fetchUserInfo() {
  // get the id token of the signed in user
  const {idToken} = await Auth.currentSession();
  // get the tenant custom attribute from the id token
  const theuser = {"band":idToken.payload['custom:band'], "email":idToken.payload['email']};
  return theuser;
}

async function SetUser(snapshotChange, user) {
  var userExists = false;
  snapshotChange.forEach((doc) => {
                      if(String(doc.data().username).toLowerCase() == String(user.username).toLowerCase()) {
                        userExists = true;
                        //thetenant = doc.data().band
                        app.config.globalProperties.$tenant = doc.data().band
                        app.config.globalProperties.$Instrument = doc.data().instrument
                        app.config.globalProperties.$defaultpart = doc.data().part;
                        let dbRef = db.collection('NextSong').doc(doc.data().band);
                          dbRef.get().then((doc) => {
                              app.config.globalProperties.$school = doc.data().school
                          }).catch((error) => {
                              console.log(error)
                          })
                        console.log("HERE!")
                          if(doc.data().approved == 'admin') {
                            app.config.globalProperties.$isAdmin = true;
                            console.log(app.config.globalProperties.$isAdmin)
                          }
                      }
                  })
  if(!userExists) {
    var userpush = {};
    fetchUserInfo().then((value) => {
      userpush.band = value.band;
      userpush.approved = 'false';
      userpush.instrument = "";
      userpush.fullname = "";
      userpush.email = value.email;
      userpush.username = user.username;
      db.collection('pendingusers').add(userpush).then(() => {
            console.log("Updated!");
            userExists = true;
        }).catch((error) => {
            console.log(error);
        });
    })
  }
    
}
<template>
<div v-if="invitevalid == true" style="margin: 50px">
<h2>Sign Up</h2>
      <div class="form-group">
          <label>Username (No spaces)</label>
          <input @change="removeSpaces()" style="border: 2px solid #484848" placeholder="Username" v-model='user.username' class='form-control' />
      </div>
      <div class="form-group">
          <label>Full Name</label>
          <input style="border: 2px solid #484848" placeholder="First and last name" v-model='user.fullname' class='form-control' />
      </div>
      <div class="form-group">
          <label>Email</label>
          <input style="border: 2px solid #484848" type="email" placeholder="Email" v-model='user.email' class='form-control' />
      </div>
      <div class="form-group">
          <label>Password (Requires at least 1 capital letter and 1 number)</label>
          <input style="border: 2px solid #484848" type="password" placeholder="Password" v-model='user.password' class='form-control' />
      </div>
      <div class="form-group">
          <label>Confirm Password</label>
          <input style="border: 2px solid #484848" type="password" placeholder="Confirm Password" v-model='confirmpassword' class='form-control' />
      </div>
      
      <br>
      <button style="border: 2px solid #484848" v-on:click='signUp' class='btn btn-1 btn-block'>Sign Up</button><br>
</div>

<div v-if="invitevalid == false">
    <h1 style="text-align: center">Invalid Invite Link. <router-link class="nav-link text-blue" to="/">Go Back</router-link></h1>
</div>
</template>

<script>
import { db } from '../firebaseDb';
import Auth from '@aws-amplify/auth';
export default {
        data() {
            return {
                invitevalid: false,
                user: {
                    username: "",
                    password: "",
                    email: "",
                    band: "",
                    approved: false,
                    fullname: "",
                },
                userpush: {
                    username: "",
                    email: "",
                    band: "",
                    approved: false,
                    invitelink: "",
                },
                key: "",
                currentTenant: "",
                NextSong: {},
                confirmpassword: "",
            }
        },
        created() {
            //If signed in, sign out
            db.collection('NextSong').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                   if(doc.data().invitelink == this.$route.params.id) {
                        this.invitevalid = true;
                        this.currentTenant = doc.data().tenant;
                        this.user.band = this.currentTenant;
                        this.NextSong.suggestions = [];
                        this.NextSong.tenant = doc.data().tenant;
                        this.NextSong.invitelink = doc.data().invitelink;
                        this.NextSong.first = doc.data().first;
                    }
                })
                });
        },
        mounted() {
          Auth.currentAuthenticatedUser()
          .then(() => {
            this.signOut();
          })
        },
        methods: {
            async signOut() {
                  await Auth.signOut()
                  console.log("Signed Out!")
              },
            removeSpaces() {
                this.user.username = this.user.username.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '')
            },
            async signUp() {
                this.user.username = this.user.username.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '')
                if(this.confirmpassword == this.user.password) {
                        if(this.NextSong.first == true) {
                                this.user.approved = "admin";
                            }
                            else {
                                
                                
                                
                                //CHANGE THIS!!!!!!!
                                
                                
                                
                                
                                this.user.approved = "true";
                            }
                      const { username, password, email, band } = this.user
                      console.log(this.user);
                      console.log(band);
                      try {
                          await Auth.signUp({
                            username, password, attributes: { email, 'custom:band': band, 'custom:approved': this.user.approved }
                          })
                          if(this.NextSong.first == true) {
                                this.NextSong.first = false;
                                db.collection('NextSong').doc(this.NextSong.tenant).update(this.NextSong).then(() => {
                                    console.log("Admin Created!")
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }
                          this.userpush.username = this.user.username;
                      this.userpush.email = this.user.email;
                      this.userpush.band = this.user.band;
                      this.userpush.approved = this.user.approved;
                      this.userpush.instrument = "";
                      this.userpush.fullname = this.user.fullname;
                      db.collection('pendingusers').add(this.userpush).then(() => {
                            console.log("Updated!");
                            this.$router.push('/')
                        }).catch((error) => {
                            console.log(error);
                        });
                          alert("Account Created! Waiting for confirmation...");
                          this.user = {
                                  username: '',
                                  password: '',
                                  email: '',
                                }
                        
                      } catch (error) {
                      if(String(error) !== "Error: Pending sign-in attempt already in progress") {
                        if(String(error).includes("validation error") || String(error).includes("did not conform with policy")) {
                          alert("Password security is too low. A password requires at least one captial letter and at least one number")
                        }
                        if(String(error).includes("auto verified was set to true")) {
                          alert("Sign up requires an email.")
                        }
                        if(!String(error).includes("validation error") && !String(error).includes("did not conform with policy") && !String(error).includes("auto verified was set to true")) {
                          alert(String(error).split(':')[1]);
                        }
                      }
                      }
                     await Auth.signIn(username, password)
                }
                else {
                    this.confirmpassword = "";
                    alert("Passowords don't match.")
                }
    },
        }
    }
</script>
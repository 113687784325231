import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCGVK2PB3aQxOh6942UWMl7DDCHlZjbO2Y",
  authDomain: "adabands-ccada.firebaseapp.com",
  projectId: "adabands-ccada",
  storageBucket: "adabands-ccada.appspot.com",
  messagingSenderId: "266623277311",
  appId: "1:266623277311:web:e9101e8cebe572537314ca",
  measurementId: "G-21X53FNSEV"
}

const firebaseConfig2 = {
  apiKey: "AIzaSyB8-C0gTCNy-jsKYuiC9XLY2ju_cSxl7JI",
  authDomain: "adapa-3fae7.firebaseapp.com",
  projectId: "adapa-3fae7",
  storageBucket: "adapa-3fae7.appspot.com",
  messagingSenderId: "500525155468",
  appId: "1:500525155468:web:fd49edbe666721d68467a9",
  measurementId: "G-ERPZBMDL2F"
}

const firebaseApp = firebase.initializeApp(firebaseConfig, 'Band');
const firebaseApp2 = firebase.initializeApp(firebaseConfig2, 'PA');

export const db = firebaseApp.firestore();
export const dbPA = firebaseApp2.firestore();
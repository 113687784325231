import AWS from 'aws-sdk'
const stripe = require('stripe')('sk_test_51LKVZhK75fzQLNGsyI2WVKRg5s1xNfMXHN3SiS1Ee4gOrWgVzKrvkbqUW3XfoNY9r73r3AnXv7tccmIhV1SRxIC400c4ZMxAZa')

AWS.config.update({
  accessKeyId: 'AKIAR4VPX6INOZFWUCYV',
  secretAccessKey: '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',
  region: 'us-west-2',
});
const cognito = new AWS.CognitoIdentityServiceProvider();
var uploadedPDF ="";
//var uploadedImage = "";

async function UploadToS3(file, name) {
    console.log(file)
    const s3 = new AWS.S3({
      accessKeyId: "AKIAR4VPX6INOZFWUCYV",
      secretAccessKey: "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",
    })
    
    uploadedPDF = await s3.upload({
      Bucket: "band-project-bucket",
      Key: name,
      Body: file,
      ContentDisposition:"inline",
      //ContentType:"application/pdf",
      ACL:'public-read'
    }).promise()
}

async function DeleteS3(name) {
    const s3 = new AWS.S3({
      accessKeyId: "AKIAR4VPX6INOZFWUCYV",
      secretAccessKey: "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",
    })
    
    await s3.deleteObject({
      Bucket: "band-project-bucket",
      Key: name,
    }).promise()
}

function getS3Link() {
  console.log(uploadedPDF.Location);
  return uploadedPDF.Location;
}

async function deleteUser(username) {
    await cognito.adminDeleteUser({
    UserPoolId: 'us-west-2_8Zxzk4CM2',
    Username: username,
  }).promise();
}

async function checkPayment(checkoutID) {
  if(checkoutID != "") {
    const session = await stripe.checkout.sessions.retrieve('cs_test_a11e9br0LoWKUZFVU2SVa6fHlbJcirFd2ZA2p0SXAFc09gStRamv7DpN2K');
    console.log(session)
  }
  else {
    return false;
  }
}

export {UploadToS3};
export {getS3Link};
export {DeleteS3};

export {deleteUser}
export {checkPayment}
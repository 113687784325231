<template>
  <br>
  <h3 style="width: 75%; margin-left:auto;
margin-right:auto;"><input type='text' class="form-control" placeholder="Search for an event by name or date" v-model="search"/></h3>
<div style="overflow-y:auto; height: 750px">
    <div class="card-columns" v-for="event in filteredSearch" :key="event.id">


  <div class="card text-dark card bg-light" style="width: 75%; margin-left:auto;
margin-right:auto; margin-bottom:0px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
    <div class="card-header"><h2>{{event.title}}</h2></div>
    <div v-if="event.loc != null && event.loc != ''" class="card-body"><b>Location: </b> <a :href="'https://maps.google.com/maps?q=' + event.address" target="_blank">{{event.loc}}</a></div>
    <div class="card-body"><b>Activity:</b> {{event.group}}</div>
    <div class="card-body"><b>Starts:</b> {{ new Date(event.start).toLocaleString([], {weekday: 'long'}) }}
                        {{ new Date(event.start).toLocaleString([], {year: 'numeric', month: 'long', day: 'numeric'}) }} at
                        {{ new Date(event.start).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</div>
    <div class="card-body"><b>Ends:</b> {{ new Date(event.end).toLocaleString([], {weekday: 'long'}) }}
    {{ new Date(event.end).toLocaleString([], {year: 'numeric', month: 'long', day: 'numeric'}) }} at
    {{ new Date(event.end).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</div>
    <div v-if="event.details != null && event.details != ''" class="card-body"><b>Details:</b> {{event.details}}</div>
    <div class="card-body"><b>Duration:</b> {{timeConvert(Math.floor(((new Date(event.end).getTime() - new Date(event.start).getTime()) / 1000) / 60))}}</div>
</div>
<br>
</div>
</div>
</template>

<script>
var theid = "";
//TODO: Modal pop up to add event
import { db } from '../firebaseDb';
import { dbPA } from '../firebaseDb';


export default {
  name: "App",
  data() {
            return {
              Events: [],
            search: "",
            }
        },
        computed: {
            filteredSearch:function() {
              return this.Events.filter((event) => {
                    var theEvent = event.title + new Date(event.start).toLocaleString([], {'weekday': 'long', 'month': 'long', 'day': 'numeric', 'hour': '2-digit', 'minute': '2-digit'});
                    return theEvent.toLowerCase().includes(this.search.toLowerCase());
                }).sort((objA, objB) => new Date(objA.start) - new Date(objB.start))
            }
        },
  created() {
      //Set events to db
      db.collection('CalendarEvents').onSnapshot((snapshotChange) => {
                this.Events = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().school == this.$school) {
                        this.Events.push({
                            id: doc.id,
                            title: doc.data().title,
                            start: doc.data().start,
                            details: doc.data().details,
                            end: doc.data().end,
                            address: doc.data().address,
                            group: "Band",
                            loc: doc.data().loc,
                        })
                    }
                });
                dbPA.collection('CalendarEvents').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(doc.data().school == this.$school) {
                        this.Events.push({
                            id: doc.id,
                            title: doc.data().title,
                            start: doc.data().start,
                            details: doc.data().details,
                            end: doc.data().end,
                            address: doc.data().address,
                            group: "Performing Arts",
                            loc: doc.data().loc,
                        })
                    }
                });
            })
            })
            
  },
  methods: {
    SetEvent(info) {
      this.event.title = info.event.title;
      this.event.start = info.event.start;
      this.event.end = info.event.end;
      this.event.tenant = info.event.tenant;
    },
    closeModal() {
      document.getElementById('myModalEdit').style.display = 'none'
    },
    deleteEvent() {
      if (window.confirm("Do you really want to delete?")) {
              db.collection("CalendarEvents").doc(theid).delete().then(() => {
                document.getElementById('myModalEdit').style.display = 'none'
                alert("Event Deleted!")
              })
              }
    },
    AddEvent() {
      //Add event to database
      db.collection('CalendarEvents').add(this.event).then(() => {
        alert("Event Added!")
        this.event = {
          title: "",
          start: "",
          end: "",
          details: "",
          tenant: this.$tenant,
        }
        window.location.reload();
      })
      //this.calendarOptions.events.splice(0, 0, this.event);
    },
    editEventStart(event) {
      this.editEvent = event;
    },
    EditEvent() {
      if(this.editEvent.details == null) {
        this.editEvent.details = ''
      }
      db.collection('CalendarEvents').doc(this.editEvent.id).update(this.editEvent).then(() => {
            alert("Updated");
            this.editEvent = {}
            window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
    },
    DeleteEvent(event) {
      if(window.confirm("Are you sure you want to delete "+event.title+"? It will be gone forever.")) {
        db.collection('CalendarEvents').doc(event.id).delete()
      }
    },
    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + " hour(s) and " + rminutes + " minute(s).";
      }
  },
  
};
</script>

<style>

</style>